<template>
    <nuxt-link
        v-if="!href"
        :to="to"
        :event="disabled ? '' : 'click'"
        :active-class="activeClass"
        :exact-active-class="exactActiveClass"
        :class="noUnderline ? 'no-underline' : ''"
        @mouseover="cursorOn"
        @mouseleave="cursorOff"
    >
        <slot></slot>
    </nuxt-link>
    <a
        v-else
        :href="href"
        target="_blank"
        :class="noUnderline ? 'no-underline' : ''"
        @mouseover="cursorOn"
        @mouseleave="cursorOff"
    >
        <slot></slot>
    </a>
</template>
<script>
export default {
    setup() {
        const cursorStore = useCursorStore()

        return { cursorStore }
    },

    props: {
        to: {
            type: String,
            default: '/',
        },
        href: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            deafult: false,
        },
        activeClass: {
            type: String,
            default: 'nuxt-link-active',
        },
        exactActiveClass: {
            type: String,
            default: 'nuxt-link-exact-active',
        },
        noUnderline: {
            type: Boolean,
            default: false,
        },
        cursorType: {
            type: String,
            default: 'link-hover',
            validator(value) {
                return ['link-hover', 'view', 'register', 'go'].includes(value)
            },
        },
        cursorText: {
            type: String,
            default: '',
        },
    },

    methods: {
        cursorOn() {
            if (this.cursorText) {
                this.cursorStore.setCursorText(this.cursorText)
                this.cursorStore.setCursorType(this.cursorType || 'default')
                return
            }

            this.cursorStore.setCursorType(this.cursorType)
            this.cursorStore.setCursorText('')
        },

        cursorOff() {
            if (this.cursorText) {
                this.cursorStore.setCursorText('')
                this.cursorStore.setCursorType('default')
                return
            }

            this.cursorStore.setCursorType('default')
        },
    },
}
</script>
