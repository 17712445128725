<template>
    <div>
        <MainNav />
        <CustomCursor />
        <main class="ErrorPage">
            <div class="ErrorPage__content">
                <div class="ErrorPage__content-wrapper">
                    <h1 class="ErrorPage__title">
                        {{ error?.statusCode || 'Error' }}
                    </h1>
                    <h2 class="ErrorPage__subtitle">
                        <template v-if="error?.statusCode === 404">
                            Page not found
                        </template>
                        <template v-else> Something has gone wrong </template>
                    </h2>
                    <CustomLink
                        v-if="description && latestCaseStudy.url"
                        :to="latestCaseStudy.url"
                        class="ErrorPage__link"
                    >
                        {{ description }}
                        <FontIcon
                            icon="arrow"
                            size="x-small"
                            class="ErrorPage__link-icon"
                        />
                    </CustomLink>
                </div>
            </div>
            <div class="ErrorPage__media" v-if="image?.url">
                <CustomLink
                    v-if="latestCaseStudy.url"
                    :to="latestCaseStudy.url"
                    class="ErrorPage__media-link"
                >
                    <SmartImage
                        class="ErrorPage__image"
                        :url="image.url"
                        :alt="image.alt"
                        :width="image.dimensions.width"
                        :height="image.dimensions.height"
                        :as-bg="true"
                        :srcs="[
                            { width: 600 },
                            { width: 1000 },
                            { width: 1300 },
                        ]"
                        object-position="70% 50%"
                    />
                </CustomLink>
            </div>
        </main>
    </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
    error: Object as () => NuxtError,
})

const prismic = usePrismic()
const { data: latestCaseStudy } = await useAsyncData(
    'error-page-case-study',
    () =>
        prismic.client.getFirst({
            filters: [prismic.filter.at('document.type', 'case_study')],
            fetchLinks: ['brand.client_name'],
        }),
)

const description = computed(() => {
    if (latestCaseStudy.value?.data) {
        if (latestCaseStudy.value.data.brand.data?.client_name) {
            return (
                'Check out our project with ' +
                latestCaseStudy.value.data.brand.data.client_name
            )
        }

        return latestCaseStudy.value.data.title
    }

    return
})

const image = computed(() => {
    if (latestCaseStudy.value?.data?.hero_image?.url) {
        return latestCaseStudy.value?.data?.hero_image
    }

    return null
})
</script>

<style lang="scss" scoped>
.ErrorPage {
    @include grid;

    min-height: 100vh;
    min-height: 100dvh;
    margin-top: calc(var(--nav-offset) * -1);
}

.ErrorPage__content {
    order: 2;
    grid-column: 2 / span 22;
    display: flex;
    flex-direction: column;

    @include large-up {
        order: 1;
        grid-column: 2 / span 11;
    }
}

.ErrorPage__content-wrapper {
    margin-top: auto;
    margin-bottom: auto;
}

.ErrorPage__media {
    order: 1;
    grid-column: span 24;

    @include large-up {
        order: 2;
        grid-column: span 12;
    }
}

.ErrorPage__title {
    @include heading-1;
}

.ErrorPage__subtitle {
    @include heading-2;
}

.ErrorPage__link {
    @include body-lg;

    display: inline-block;
    text-decoration: none;
    margin-top: 1rem;
    font-weight: var(--font-weight-regular);

    @include small-only {
        text-decoration: underline;
    }

    @include large-up {
        margin-top: 3rem;
    }

    &:hover {
        .ErrorPage__link-icon {
            transform: translateX(0.25rem) rotate(45deg);
        }
    }
}

.ErrorPage__link-icon {
    margin-left: 1rem;
    transform: translateX(0px) rotate(45deg);
    transition: transform 0.3s ease;
    display: none;

    @include medium-up {
        display: inline-block;
        vertical-align: middle;
    }
}

.ErrorPage__image {
    width: 100%;
    height: 100%;
}

.ErrorPage__media-link {
    &:hover {
        .ErrorPage__image {
            transform: scale(1.01);
        }
    }
}

.ErrorPage__media-link {
    .ErrorPage__image {
        transform: scale(1);
        transition: transform 0.3s ease;
    }
}
</style>
