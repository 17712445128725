import { defineStore } from 'pinia'

export const useCoreStore = defineStore('core', {
    state: () => ({
        logoColor: 'white',
        menuOpen: false,
        promotion: {},
    }),

    actions: {
        setLogoColor(newLogoColor) {
            this.logoColor = newLogoColor
        },
        setMenuOpen(newMenuOpen) {
            this.menuOpen = newMenuOpen
        },
        setPromotion(newPromotion) {
            this.promotion = newPromotion
        },
    },
})
