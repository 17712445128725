<template>
    <div
        v-if="promoBanner && !closedInSession && cookie !== 'closed'"
        class="PromoBanner"
        :class="{
            'PromoBanner--with-custom-bg': bgColour,
            'PromoBanner--with-custom-close-btn-colour': closeButtonIconColour,
        }"
        ref="promoBannerEl"
    >
        <div class="PromoBanner__container">
            <component
                :is="
                    promoBanner.data.promotion_bar_internal_link.url ||
                    promoBanner.data.promotion_bar_external_link.url
                        ? CustomLink
                        : 'div'
                "
                :to="promoBanner.data.promotion_bar_internal_link.url"
                :url="promoBanner.data.promotion_bar_external_link.url"
                class="PromoBanner__content"
            >
                <SliceZone
                    v-if="promoBanner.data.slices"
                    wrapper="span"
                    :slices="promoBanner.data.slices ?? []"
                    :components="components"
                    class="PromoBanner__inner"
                />
            </component>
            <CustomButton theme="plain" @click="closePromoBanner">
                <FontIcon
                    icon="close"
                    color="black"
                    class="PromotionBanner__close-icon"
                />
            </CustomButton>
        </div>
    </div>
</template>

<script setup>
import { CustomLink } from '#components'
import { components } from '~/slices'

const { hook } = useNuxtApp()

const props = defineProps({
    promoBanner: { type: Object, default: () => ({}) },
})

const bgColour = ref(props.promoBanner.data.theme.data.theme_base_colour)
const closeButtonIconColour = ref(
    props.promoBanner.data.close_button_icon_colour,
)
const closedInSession = ref(false)
const cookie = useCookie('promo-bar-' + props.promoBanner.uid, {
    maxAge: 2592000,
})
const promoBannerObserver = ref(null)
const promoBannerEl = ref()
const emit = defineEmits(['promoBarInView'])

onMounted(() => {
    if (!promoBannerEl.value) return

    promoBannerObserver.value = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            emit('promoBarInView', entry.isIntersecting)
        })
    })

    promoBannerObserver.value.observe(promoBannerEl.value)

    hook('page:loading:end', () => {
        emit('promoBarInView', true)
    })
})

onBeforeUnmount(() => {
    promoBannerObserver.value.disconnect()
})

function closePromoBanner() {
    closedInSession.value = true
    cookie.value = 'closed'
}
</script>

<style lang="scss" scoped>
.PromoBanner {
    @include grid;
    width: 100%;
    background-color: var(--lime-green);
    min-height: 2rem;
    padding: 0.5rem;
    position: relative;
    z-index: 11;
    height: var(--promo-bar-height);
    overflow: hidden;
}

.PromoBanner--with-custom-bg {
    background-color: v-bind(bgColour);
}

.PromoBanner--with-custom-close-btn-colour {
    .PromotionBanner__close-icon {
        --font-icon-colour: v-bind(closeButtonIconColour);
    }
}

.PromoBanner__container {
    display: flex;
    align-items: center;
    grid-column: 1 / span 24;

    @include medium-up {
        grid-column: 2 / span 22;
    }
}

.PromoBanner__content {
    display: flex;
    flex-grow: 1;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    overflow: hidden;
}

.PromoBanner__inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include small-only {
        row-gap: 0.5rem;
    }

    @include medium-up {
        flex-direction: row;
    }
}
</style>
