<template>
    <div
        class="font-icon"
        :class="['font-icon--' + size, 'font-icon__colour--' + color]"
        :style="styles"
    >
        <NuxtIcon :name="icon" filled />
    </div>
</template>

<script>
import { validIcons } from '~/assets/js/config/validators'

export default {
    name: 'FontIcon',

    props: {
        icon: {
            type: String,
            required: true,
            validator(value) {
                return validIcons.includes(value)
            },
        },
        size: {
            default: 'medium',
            type: String,
            validator(value) {
                return [
                    'xx-small',
                    'x-small',
                    'small',
                    'medium',
                    'large',
                    'x-large',
                    'stretch',
                ].includes(value)
            },
        },
        color: {
            default: 'white',
            type: String,
            validator(value) {
                return ['black', 'white', 'lime-green'].includes(value)
            },
        },
        rotate: {
            type: Number,
            default: 0,
            validator(value) {
                return value <= 360
            },
        },
    },

    computed: {
        styles() {
            if (this.rotate > 0) {
                return {
                    transform: 'rotate(' + this.rotate + 'deg)',
                }
            }

            return 0
        },
    },
}
</script>

<style lang="scss">
.font-icon {
    display: flex;
    align-items: center;
    aspect-ratio: 1/1;

    --font-icon-colour: var(--font-icon-colour);

    .nuxt-icon {
        width: 100%;
    }

    svg {
        transition: fill 0.3s ease;
        margin-bottom: 0;
        width: 100%;
        height: 100%;
    }
}

.font-icon--xx-small {
    width: 0.75rem;
}

.font-icon--x-small {
    width: 1rem;
}

.font-icon--small {
    width: 1.25rem;
}

.font-icon--medium {
    width: 1.5rem;
}

.font-icon--large {
    width: 2rem;
}

.font-icon--x-large {
    width: 4rem;
}

.font-icon--stretch {
    width: 100%;
}

.font-icon__colour--white svg {
    fill: var(--font-icon-colour, var(--white));
}

.font-icon__colour--black svg {
    fill: var(--font-icon-colour, var(--black));
}

.font-icon__colour--lime-green svg {
    fill: var(--font-icon-colour, var(--lime-green));
}
</style>
