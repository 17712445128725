import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter()

    const {
        public: { sentry },
    } = useRuntimeConfig()

    if (!sentry.dsn) {
        console.warn(
            'Sentry DSN not set, skipping Sentry client initialization',
        )
        return
    }

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,
        allowUrls: [
            /https?:\/\/((www)\.)?eightandfour\.vercel\.app/,
            /https?:\/\/((www)\.)?eightandfour\.com/,
            'http://localhost',
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],

        tracesSampleRate: sentry.environment === 'production' ? 0.2 : 1.0,

        replaysSessionSampleRate:
            sentry.environment === 'production' ? 0.1 : 1.0,
        replaysOnErrorSampleRate:
            sentry.environment === 'production' ? 0.1 : 1.0,
    })

    return {
        provide: {
            sentry: {
                captureException: (exception) => {
                    // If exception.value is itself an exception then we want to capture that
                    if (exception.value instanceof Error) {
                        return Sentry.captureException(exception.value)
                    }
                    return Sentry.captureException(exception)
                },
                withScope: Sentry.withScope,
            },
        },
    }
})
