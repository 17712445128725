<template>
    <div
        class="LottieIcon"
        :class="[
            colour ? `LottieIcon--colour-${colour}` : '',
            noFill ? 'LottieIcon--no-fill' : '',
        ]"
        ref="lottieContainer"
    />
</template>

<script setup>
import lottie from 'lottie-web'

const props = defineProps({
    icon: {
        required: true,
        default: 'balloons',
    },
    play: {
        required: true,
        default: false,
    },
    colour: {
        required: false,
        default: '',
        validator(value) {
            return ['', 'white', 'black'].includes(value)
        },
    },
})

import * as balloonData from '~/assets/lottie/balloons.json'
import * as cocktailData from '~/assets/lottie/cocktail.json'
import * as globeData from '~/assets/lottie/globe.json'
import * as healthData from '~/assets/lottie/health.json'
import * as hybridWorkingData from '~/assets/lottie/hybrid-working.json'
import * as locationData from '~/assets/lottie/location.json'
import * as peopleCentric from '~/assets/lottie/people-centric.json'
import * as professionalDevelopmentData from '~/assets/lottie/professional-development.json'
import * as suitcaseData from '~/assets/lottie/suitcase.json'
import * as burgerconData from '~/assets/lottie/burgercon.json'
import * as airplaneData from '~/assets/lottie/airplane.json'
import * as seasideResortData from '~/assets/lottie/seaside-resort.json'

const animationData = computed(() => {
    switch (props.icon) {
        case 'balloons':
            return balloonData
        case 'cocktail':
            return cocktailData
        case 'globe':
            return globeData
        case 'health':
            return healthData
        case 'hybrid-working':
            return hybridWorkingData
        case 'location':
            return locationData
        case 'people-centric':
            return peopleCentric
        case 'professional-development':
            return professionalDevelopmentData
        case 'suitcase':
            return suitcaseData
        case 'burgercon':
            return burgerconData
        case 'airplane':
            return airplaneData
        case 'seaside-resort':
            return seasideResortData
    }
})

const noFill = computed(() => {
    return ['cocktail'].includes(props.icon)
})

const lottieContainer = ref()
const lottieInstance = ref()

onMounted(() => {
    lottieInstance.value = lottie.loadAnimation({
        container: lottieContainer.value, // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData.value.default, // the path to the animation json
    })
})

watch(
    () => props.play,
    (newValue) => {
        if (newValue === true) {
            lottieInstance.value.setDirection(1)
            lottieInstance.value.goToAndPlay(1, true)
        } else {
            lottieInstance.value.setDirection(-1)
            lottieInstance.value.play()
        }
    },
)
</script>

<style lang="scss">
.LottieIcon {
    width: 100%;
    aspect-ratio: 1/1;

    svg path {
        fill: var(--white) !important;
        stroke: var(--white) !important;
    }
}

.LottieIcon--colour-black {
    svg path {
        fill: var(--black) !important;
        stroke: var(--black) !important;
    }
}

.LottieIcon--no-fill {
    svg path {
        fill: none !important;
    }
}
</style>
