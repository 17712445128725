<template>
    <footer class="MainFooter">
        <div class="MainFooter__head">
            <CustomLink to="/" title="Home" class="MainFooter__logo">
                <img
                    class="MainFooter__logo-white"
                    src="~/assets/images/svg/eight_four_logo_white.svg"
                    alt="eight&four"
                    title="Home"
                />
                <img
                    class="MainFooter__logo-black"
                    src="~/assets/images/svg/eight_four_logo_black.svg"
                    alt="eight&four"
                    title="Home"
                />
            </CustomLink>
            <ul class="MainFooter__head-links">
                <li
                    v-for="headLink in footer.data.head_links.filter(
                        (headLink) => headLink.link_text,
                    )"
                >
                    <CustomLink
                        :to="headLink.internal_link?.url"
                        :href="headLink.external_link?.url"
                        class="MainFooter__head-link"
                    >
                        {{ headLink.link_text }}
                    </CustomLink>
                </li>
            </ul>
        </div>
        <div class="MainFooter__body">
            <div class="MainFooter__body-group">
                <div
                    v-for="column in footer.data.column"
                    class="MainFooter__body-column"
                >
                    <PrismicRichText
                        :field="column.column_content"
                        class="MainFooter__body-column-content"
                    />
                </div>
            </div>
            <div class="MainFooter__body-links">
                <h5
                    v-if="footer.data.body_links_title"
                    class="MainFooter__body-links-title"
                >
                    {{ footer.data.body_links_title }}
                </h5>
                <ul class="MainFooter__body-links-list">
                    <li
                        v-for="bodyLink in footer.data.body_links.filter(
                            (bodyLink) => bodyLink.link_text,
                        )"
                    >
                        <CustomLink
                            :to="bodyLink.internal_link?.url"
                            :href="bodyLink.external_link?.url"
                            class="MainFooter__body-link"
                        >
                            {{ bodyLink.link_text }}
                        </CustomLink>
                    </li>
                </ul>
            </div>
        </div>
        <div class="MainFooter__copyright">
            © Copyright {{ copyrightYear }}
            <ul class="MainFooter__copyright-links">
                <li>
                    <CustomLink
                        href="/cookie-policy"
                        class="MainFooter__copyright-link"
                    >
                        Cookie Policy
                    </CustomLink>
                </li>
                <li>
                    <CustomLink
                        href="/privacy-policy"
                        class="MainFooter__copyright-link"
                    >
                        Privacy Policy
                    </CustomLink>
                </li>
                <li>
                    <a
                        href="#"
                        class="termly-display-preferences MainFooter__copyright-link"
                        >Consent Preferences</a
                    >
                </li>
            </ul>
        </div>
    </footer>
</template>

<script setup>
const copyrightYear = computed(() => {
    return new Date().getFullYear()
})

const prismic = usePrismic()

const { data: footer } = await useAsyncData('[footer]', () =>
    prismic.client.getSingle('footer'),
)
</script>

<style lang="scss">
.theme--white {
    .MainFooter,
    .MainFooter__body-link,
    .MainFooter__copyright-link,
    .MainFooter__head-link {
        color: var(--black);
    }

    .MainFooter__logo-black {
        display: block;
    }

    .MainFooter__logo-white {
        display: none;
    }
}

.MainFooter {
    @include grid;

    padding-top: 8rem;
}

.MainFooter__logo-black {
    display: none;
}

.MainFooter__head,
.MainFooter__body,
.MainFooter__copyright {
    grid-column: 2 / span 22;
}

.MainFooter__head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1.5rem;
}

.MainFooter__head-links {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    gap: 1.5rem;

    li {
        width: 100%;

        @include medium-up {
            width: auto;
        }
    }
}

.MainFooter__head-link {
    @include label-lg;

    text-transform: uppercase;
    font-weight: var(--font-weight-regular);
}

.MainFooter__body {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 4rem;
    justify-content: space-between;
    padding-top: 1rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.MainFooter__body-group {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3rem;
    row-gap: 2rem;
    justify-content: space-between;
}

.MainFooter__body-column-content {
    h4 {
        @include heading-3;

        margin-bottom: 1rem;
    }

    a {
        @include body-caption;

        display: block;
        margin-top: 1rem;
    }
}

.MainFooter__body-links {
    @include large-up {
        text-align: right;
    }
}

.MainFooter__body-links-title {
    @include heading-3;

    margin-bottom: 1.5rem;
}

.MainFooter__body-links-list {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    @include large-up {
        justify-content: flex-end;
    }

    li {
        width: 100%;

        @include medium-up {
            width: calc(50% - 1rem);
        }
    }
}

.MainFooter__body-link {
    @include label-lg;

    text-transform: uppercase;
    font-weight: var(--font-weight-regular);
}

.MainFooter__copyright {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 2rem;
    padding-top: 1rem;
    border-top: 1px solid var(--white);
}

.MainFooter__copyright-links {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4rem;
    row-gap: 1rem;
    padding-bottom: 2.5rem;
}
</style>
