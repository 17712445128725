export const validIcons = [
    'arrow',
    'caret',
    'close',
    'pause',
    'play',
    'plus',
    'volume-on',
    'volume-off',
    'burgercon',
    'fullscreen',
    'linkedin',
    'x',
    'drag',
    'platform12',
    'coach',
    'echo',
    'focus',
    'scholar',
    'loop',
]
