const VALID_EVENT_NAMES = []

export default defineNuxtPlugin(() => {
    function customEvent(eventName, properties = {}) {
        const _hsq = (window._hsq = window._hsq || [])

        const matchedEventName = VALID_EVENT_NAMES.find(
            (validName) => validName === eventName,
        )

        if (!matchedEventName) {
            throw new Error(
                'Could not find ' + eventName + ' as valid Hubspot event',
            )
        }

        _hsq.push([
            'trackCustomBehavioralEvent',
            {
                name: matchedEventName,
                properties,
            },
        ])
    }

    function identifyUser(email) {
        const _hsq = (window._hsq = window._hsq || [])

        _hsq.push([
            'identify',
            {
                email,
            },
        ])
    }

    function pageView(path) {
        const _hsq = (window._hsq = window._hsq || [])

        if (!path) {
            throw new Error(
                'A path must be passed to the Hubspot pageView function',
            )
        }

        _hsq.push(['setPath', path])
        _hsq.push(['trackPageView'])
    }

    return {
        provide: {
            hubspot: {
                customEvent,
                pageView,
                identifyUser,
            },
        },
    }
})
