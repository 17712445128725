<template>
    <div
        :class="{
            'menu-open': coreStore.menuOpen,
            'has-promo-bar': !cookie && promoBanner,
            'promo-bar-scrolled-out': !promoBarInView,
        }"
    >
        <Teleport to="#header-teleport-container">
            <PromoBanner
                v-if="promoBanner"
                :promo-banner="promoBanner"
                @promoBarInView="handlePromoBarInView"
            />
            <MainNav :showing-promo-bar="showingPromoBar" />
        </Teleport>
        <PromoBanner
            v-if="promoBanner"
            :promo-banner="promoBanner"
            @promoBarInView="handlePromoBarInView"
            class="PromoBanner--mobile"
        />
        <MainNav :showing-promo-bar="showingPromoBar" class="MainNav--mobile" />
        <div id="smooth-wrapper" class="PageBody">
            <div id="smooth-content">
                <NuxtPage />
                <MainFooter v-if="$route.name !== 'work-work'" />
            </div>
        </div>
        <div id="teleport-container" />
        <CustomCursor />
        <LoadingScreen />
    </div>
</template>
<script setup>
const route = useRoute()
const coreStore = useCoreStore()
const runtimeConfig = useRuntimeConfig()
const prismic = usePrismic()

const promoBarInView = ref(true)

const { data: promoBanner } = await useAsyncData('[promotion]', () =>
    prismic.client.getSingle('promotion', {
        fetchLinks: [
            'theme.uid',
            'theme.theme_base_colour',
            'theme.theme_base_icon_colour',
            'theme.theme_chip_colour',
            'theme.theme_chip_icon_colour',
            'theme.theme_logo',
        ],
    }),
)

function handlePromoBarInView(value) {
    promoBarInView.value = value
}

const cookie = promoBanner.value
    ? useCookie('promo-bar-' + promoBanner.value.uid)
    : ref(null)

const showingPromoBar = computed(() => {
    if (!cookie.value && promoBanner?.value && promoBarInView.value) {
        return true
    }

    return false
})

useSeoMeta(() => ({
    title: 'eight&four | A Performance Driven Social and Content Agency',
    description:
        "We're a social & end-to-end content production agency. We work with global brands looking for a dynamic, multi-lingual agency.",
    ogTitle: 'eight&four | A Performance Driven Social and Content Agency',
    ogDescription:
        "We're a social & end-to-end content production agency. We work with global brands looking for a dynamic, multi-lingual agency.",
    ogImage: process.env.BASE_URL + '/images/default-og-image.png',
    ogUrl: process.env.BASE_URL + route.path,
}))

useHead(() => ({
    link: {
        rel: 'canonical',
        href: runtimeConfig.public.baseUrl + route.path,
    },
}))
</script>

<style lang="scss">
#header-teleport-container {
    @include small-only {
        display: none;
    }

    @include large-up {
        height: 100%;
    }
}

.PromoBanner--mobile,
.MainNav--mobile {
    @include medium-up {
        display: none !important;
    }
}

.PageBody {
    @include small-only {
        margin-top: -69px; // Stops empty space between nav and page content
    }
}
</style>
