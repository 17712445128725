<template>
    <div
        ref="cursor"
        class="custom-cursor"
        :class="cursorClass"
        v-show="!hideCursor"
    >
        <span v-if="cursorText" v-html="cursorText" />
        <span v-else-if="!cursorText && cursorIcon">
            <FontIcon :icon="cursorIcon" color="black" :size="cursorSize" />
        </span>
    </div>
</template>
<script>
let cursor

export default {
    setup() {
        const cursorStore = useCursorStore()

        return { cursorStore }
    },

    computed: {
        cursorClass() {
            if (this.cursorText) {
                return (
                    'custom-cursor--text' +
                    (this.cursorStore.cursorType
                        ? ' custom-cursor--' + this.cursorStore.cursorType
                        : '')
                )
            }

            return 'custom-cursor--' + this.cursorStore.cursorType
        },
        cursorIcon() {
            if (['link-hover', 'default'].includes(this.cursorStore.cursorType))
                return null

            return this.cursorStore.cursorType
        },
        cursorText() {
            return this.cursorStore.cursorText
        },
        hideCursor() {
            return this.cursorStore.cursorType === 'hidden'
        },
        cursorSize() {
            if (['drag', 'close'].includes(this.cursorStore.cursorType)) {
                return 'large'
            }

            return 'x-large'
        },
    },

    mounted() {
        cursor = this.$refs.cursor
        cursor.style.display = 'none'

        window.addEventListener('mousemove', this.moveCursor)
        window.addEventListener('scroll', this.moveCursor)

        window.addEventListener('mousedown', () => {
            cursor.classList.add('custom-cursor--pressed')
        })

        window.addEventListener('mouseup', (e) => {
            cursor.classList.remove('custom-cursor--pressed')
            this.cursorStore.setCursorReleaseLocation({
                x: e.x,
                y: e.y,
            })
        })

        document.addEventListener('mouseleave', (e) => {
            if (cursor.style.display === 'flex') {
                cursor.style.display = 'none'
            }
        })

        document.addEventListener('mouseenter', (e) => {
            cursor.style.display = 'flex'
        })
    },

    methods: {
        moveCursor(e) {
            if (
                cursor.style.display === 'none' &&
                this.cursorStore.cursorType !== 'hidden'
            ) {
                cursor.style.display = 'flex'
            }
            cursor.style.top = e.clientY + 'px'
            cursor.style.left = e.clientX + 'px'
        },
    },
}
</script>
<style lang="scss" scoped>
.theme--white {
    .custom-cursor {
        background-color: rgba(var(--black-rgb), 0.1);
        border: 1px solid var(--black);
    }

    &.news-page,
    &.work-page {
        .custom-cursor {
            background-color: #333;
            color: var(--white);
            box-shadow: 0px 0px 8px 0px #0000004d;
            border: none;
        }

        .menu-open {
            .custom-cursor {
                background-color: rgba(var(--white-rgb), 0.5);
                border: 1px solid var(--white);
                color: var(--black);
                box-shadow: none;
            }

            .custom-cursor--close {
                background-color: var(--white);
            }
        }
    }
}

.custom-cursor {
    display: flex;
    align-items: center;
    aspect-ratio: 1/1;
    justify-content: center;
    width: 24px;
    background-color: rgba(var(--white-rgb), 0.5);
    border: 1px solid var(--white);
    position: fixed;
    z-index: 9999999999999999;
    border-radius: 100%;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition:
        width 0.15s 0s ease-in-out,
        height 0.15s 0s ease-in-out,
        background-color 0.15s 0s ease-in-out;

    @include touchscreen {
        display: none !important;
    }
}

.custom-cursor--text {
    width: auto;
    aspect-ratio: 1/1;
    background-color: var(--white);
    color: var(--black);
    text-align: center;
    font-family: var(--font-family-primary);
    font-size: var(--font-size-body-md);
    font-weight: var(--font-weight-regular);
    letter-spacing: -0.02em;
    text-transform: uppercase;
    white-space: wrap;
    line-height: 1;
    padding: 1rem;
    padding-top: 0.9rem;
}

.custom-cursor--pressed,
.custom-cursor--link-hover.custom-cursor--pressed {
    width: 32px;
    transition:
        width 0.15s 0s ease-in-out,
        height 0.15s 0s ease-in-out;
}

.custom-cursor--link-hover {
    width: 48px;
    transition:
        width 0.15s 0s ease-in-out,
        height 0.15s 0s ease-in-out,
        background-color 0.15s 0s ease-in-out;
}

.custom-cursor--pause,
.custom-cursor--play,
.custom-cursor--view,
.custom-cursor--fullscreen,
.custom-cursor--register,
.custom-cursor--go {
    min-width: 100px;
    background-color: var(--white);
    border: 1px solid var(--white);
}

.custom-cursor--go {
    font-style: italic;
    font-size: var(--font-size-body-xl);
}

.custom-cursor--drag,
.custom-cursor--close {
    width: 64px;
    background-color: var(--white);
}

.custom-cursor--close.custom-cursor--pressed {
    width: 70px;
}

.custom-cursor--hide-shrink {
    opacity: 0;
}
</style>
