<template>
    <nav
        class="MainNav"
        :class="{
            'MainNav--open': menuOpen,
            'MainNav--scroll-back': menuScrollBack,
        }"
    >
        <CustomLink
            to="/"
            title="Home"
            class="MainNav__logo"
            :class="[
                {
                    'MainNav__logo--transitioned-in': mainLogoTransitionedIn,
                },
                'MainNav__logo--' + coreStore.logoColor,
            ]"
            ref="mainLogo"
        >
            <img
                class="MainNav__logo-img MainNav__logo-white"
                src="~/assets/images/svg/eight_four_logo_white.svg"
                alt="eight&four"
                title="Home"
                :style="{ width: '100%' }"
            />
            <img
                class="MainNav__logo-img MainNav__logo-black"
                src="~/assets/images/svg/eight_four_logo_black.svg"
                alt="eight&four"
                title="Home"
                :style="{ width: '100%' }"
            />
        </CustomLink>
        <div class="MainNav__links">
            <div class="MainNav__links-wrapper">
                <div class="MainNav__quick-links">
                    <CustomLink
                        v-for="quickLink in quickLinks"
                        :to="quickLink.internal_link.url"
                        class="MainNav__links-item"
                    >
                        {{ quickLink.link_text }}
                    </CustomLink>
                </div>
                <div class="MainNav__burgercon-wrapper">
                    <CustomButton
                        theme="plain"
                        background-color
                        class="MainNav__links-item--burger"
                        @click="handleBurgerClick"
                    >
                        <LottieIcon
                            icon="burgercon"
                            class="MainNav__burger"
                            colour="white"
                            :play="menuOpen"
                        />
                    </CustomButton>
                </div>
            </div>
        </div>
        <SideNav
            :open="menuOpen"
            :links="sideMenuLinks"
            :showing-promo-bar="showingPromoBar"
            @close="menuOpen = false"
        />
    </nav>
</template>

<script setup>
const prismic = usePrismic()
const menuOpen = ref(false)
const menuScrollBack = ref(true)
const nuxtApp = useNuxtApp()
const coreStore = useCoreStore()

defineProps({
    showingPromoBar: { type: Boolean, default: false },
})

let previousScrollPosition = 0
const mainLogo = ref()
const mainLogoTransitionedIn = ref(false)
const mainLogoObserver = ref()

const { data: mainMenu } = await useAsyncData('[main-menu]', () =>
    prismic.client.getSingle('main_menu'),
)

function handleBurgerClick() {
    menuOpen.value = !menuOpen.value
}

function isScrollingDown() {
    let goingDown = false
    let scrollPosition = window.scrollY

    if (scrollPosition > previousScrollPosition) {
        goingDown = true
    }

    previousScrollPosition = scrollPosition
    return goingDown
}

function handleScroll() {
    if (isScrollingDown()) {
        menuScrollBack.value = false
    } else if (isScrollingDown() === false) {
        menuScrollBack.value = true
    }
}

function scrollThrottle() {
    return requestAnimationFrame(() => {
        handleScroll()
    })
}

const quickLinks = computed(() => {
    return mainMenu.value.data.quick_links
        .filter((quickLink) => {
            if (quickLink.internal_link.url) {
                return quickLink.internal_link.broken !== false
            }

            return true
        })
        .filter((quickLink) => quickLink.link_text)
})

const sideMenuLinks = computed(() => {
    return mainMenu.value.data.side_menu_links
        .filter((sideMenuLink) => {
            if (sideMenuLink.internal_link.url) {
                return sideMenuLink.internal_link.broken !== false
            }

            return true
        })
        .filter((sideMenuLink) => sideMenuLink.link_text)
})

onMounted(() => {
    window.addEventListener('scroll', scrollThrottle)

    mainLogoObserver.value = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                mainLogoTransitionedIn.value = true
                observer.disconnect()
            }
        })
    })

    mainLogoObserver.value.observe(mainLogo.value.$el)
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', scrollThrottle)
})

watch(
    () => menuOpen.value,
    (newValue) => {
        coreStore.setMenuOpen(newValue)

        if (newValue) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    },
)

nuxtApp.hook('page:loading:start', () => {
    menuOpen.value = false
})
</script>

<style lang="scss">
.has-promo-bar {
    .MainNav__logo {
        transform: translateY(0);

        @include small-only {
            transition-duration: 0.6s;
        }
    }

    &.promo-bar-scrolled-out {
        .MainNav__logo {
            transform: translateY(
                calc(-100% - 1.25rem - var(--promo-bar-height))
            );
        }

        .MainNav--scroll-back .MainNav__logo {
            transform: translateY(0);
        }
    }
}

.MainNav {
    @include grid;

    position: sticky;
    top: 1.25rem;
    width: 100%;
    z-index: 11;
    margin-top: 1.25rem;
}

.MainNav__logo {
    display: flex;
    grid-column: 2 / span 8;
    transform: translateY(calc(-100% - 1.25rem));
    transition: transform 0.3s linear;
    overflow: hidden;

    @include medium-up {
        grid-column: 2 / span 5;
    }

    @include large-up {
        grid-column: 2 / span 3;
    }
}

.MainNav__logo-img {
    transform: translateY(100%);
    transition: transform 1s 0.2s ease;
    max-width: 8.75rem;
}

.MainNav__logo--transitioned-in {
    .MainNav__logo-img {
        transform: translateY(0);
    }
}

.MainNav__logo--white {
    .MainNav__logo-black {
        display: none;
    }

    .MainNav__logo-white {
        display: block;
    }
}

.MainNav__logo--black {
    .MainNav__logo-white {
        display: none;
    }

    .MainNav__logo-black {
        display: block;
    }
}

.MainNav--scroll-back {
    .MainNav__logo {
        transform: translateY(0);
    }
}

.MainNav__logo-black {
    display: none;
}

.news-page {
    .MainNav__logo-black {
        display: block;
    }

    .MainNav__logo-white {
        display: none;
    }
}

.MainNav__links {
    grid-column: span 10 / 24;
    display: flex;
    justify-content: flex-end;

    @include medium-up {
        grid-column: span 16 / 24;
    }

    @include large-up {
        grid-column: span 18 / 24;
    }
}

.MainNav__links-wrapper {
    display: flex;
    z-index: 1;
    transform: translateX(0);
    transition: transform 0.5s ease;
    border-radius: 0.25rem;
}

.MainNav__links-wrapper:hover,
.MainNav--scroll-back .MainNav__links-wrapper,
.MainNav--scroll-back .MainNav__links-wrapper:hover {
    .MainNav__quick-links:before {
        transform: translateX(0);
    }

    .MainNav__links-item {
        opacity: 1;
        transform: translateX(0);

        &:nth-child(1) {
            transition-delay: 0.3s;
        }

        &:nth-child(2) {
            transition-delay: 0.2s;
        }

        &:nth-child(3) {
            transition-delay: 0.1s;
        }
    }
}

.MainNav__quick-links {
    display: none;

    @include medium-up {
        position: relative;
        display: flex;
        overflow: hidden;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            display: block;
            background-color: #0f1215;
            width: 100%;
            height: 100%;
            transition: transform 0.5s ease;
        }
    }
}

// prettier-ignore
.MainNav__quick-links:before,
.MainNav--open .MainNav__links-wrapper:hover .MainNav__quick-links:before,
.MainNav--open.MainNav--scroll-back .MainNav__links-wrapper .MainNav__quick-links:before,
.MainNav--open.MainNav--scroll-back .MainNav__links-wrapper:hover .MainNav__quick-links:before {
    transform: translateX(100%);
}

.MainNav__links-item {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    font-style: italic;
    font-family: var(--font-family-primary);
    font-weight: var(--font-weight-bold);
    padding: 0.6375rem 1.5rem;
    white-space: nowrap;
    transition-property: all;
    transition-timing-function: ease;
    transition-duration: 0.2s;
    pointer-events: all;

    &.nuxt-link-active {
        color: var(--lime-green);
    }
}

//prettier-ignore
.MainNav__links-item,
.MainNav--open .MainNav__links-wrapper:hover .MainNav__links-item,
.MainNav--open .MainNav__links-item,
.MainNav--open.MainNav--scroll-back .MainNav__links-wrapper .MainNav__links-item,
.MainNav--open.MainNav--scroll-back .MainNav__links-wrapper:hover .MainNav__links-item {
    opacity: 0;
    transform: translateX(10px);

    &:nth-child(1) {
        transition-delay: 0s;
    }

    &:nth-child(2) {
        transition-delay: 0.1s;
    }

    &:nth-child(3) {
        transition-delay: 0.2s;
    }
}

.MainNav--open .MainNav__links-wrapper:hover .MainNav__links-item,
.MainNav--open .MainNav__links-item,
.MainNav--open.MainNav--scroll-back
    .MainNav__links-wrapper
    .MainNav__links-item,
.MainNav--open.MainNav--scroll-back
    .MainNav__links-wrapper:hover
    .MainNav__links-item {
    pointer-events: none;
}

.MainNav__burgercon-wrapper {
    display: flex;
    position: relative;
    z-index: 1;
    background-color: #0f1215;
}

.MainNav__links-item--burger {
    display: block;
    padding: 0 1.75rem;
}

.MainNav__burger {
    width: 3rem;
}

.MainNav--open {
    .MainNav__links-wrapper {
        transform: translateX(calc(4vw - 16px));

        @include large-up {
            transform: translateX(calc(4vw - 16px));
        }
    }
}
</style>
