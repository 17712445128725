// Code generated by Slice Machine. DO NOT EDIT.

import { defineAsyncComponent } from 'vue'
import { defineSliceZoneComponents } from '@prismicio/vue'

export const components = defineSliceZoneComponents({
    accordion_content_block: defineAsyncComponent(
        () => import('./AccordionContentBlock/index.vue'),
    ),
    case_study_grid: defineAsyncComponent(
        () => import('./CaseStudyGrid/index.vue'),
    ),
    case_study_quote_block: defineAsyncComponent(
        () => import('./CaseStudyQuoteBlock/index.vue'),
    ),
    expander_card_carousel: defineAsyncComponent(
        () => import('./ExpanderCardCarousel/index.vue'),
    ),
    full_bleed_hero: defineAsyncComponent(
        () => import('./FullBleedHero/index.vue'),
    ),
    gallery_block: defineAsyncComponent(
        () => import('./GalleryBlock/index.vue'),
    ),
    gallery_grid: defineAsyncComponent(() => import('./GalleryGrid/index.vue')),
    image_modal_grid: defineAsyncComponent(
        () => import('./ImageModalGrid/index.vue'),
    ),
    image_with_content: defineAsyncComponent(
        () => import('./ImageWithContent/index.vue'),
    ),
    jenga_image_grid: defineAsyncComponent(
        () => import('./JengaImageGrid/index.vue'),
    ),
    legal_text_block: defineAsyncComponent(
        () => import('./LegalTextBlock/index.vue'),
    ),
    link_banner: defineAsyncComponent(() => import('./LinkBanner/index.vue')),
    logo_wall: defineAsyncComponent(() => import('./LogoWall/index.vue')),
    momentum_wall: defineAsyncComponent(
        () => import('./MomentumWall/index.vue'),
    ),
    news_post_quote_pullout: defineAsyncComponent(
        () => import('./NewsPostQuotePullout/index.vue'),
    ),
    news_post_text_block: defineAsyncComponent(
        () => import('./NewsPostTextBlock/index.vue'),
    ),
    plain_text_block: defineAsyncComponent(
        () => import('./PlainTextBlock/index.vue'),
    ),
    promotion: defineAsyncComponent(
        () => import('./PromotionBanner/index.vue'),
    ),
    promotion_bar_button: defineAsyncComponent(
        () => import('./PromotionBarButton/index.vue'),
    ),
    promotion_bar_text: defineAsyncComponent(
        () => import('./PromotionBarText/index.vue'),
    ),
    quote_pullout_block: defineAsyncComponent(
        () => import('./QuotePulloutBlock/index.vue'),
    ),
    scroller_gallery: defineAsyncComponent(
        () => import('./ScrollerGallery/index.vue'),
    ),
    stats_counter: defineAsyncComponent(
        () => import('./StatsCounter/index.vue'),
    ),
    text_hero: defineAsyncComponent(() => import('./TextHero/index.vue')),
    ticker_tape_block: defineAsyncComponent(
        () => import('./TickerTapeBlock/index.vue'),
    ),
    tile: defineAsyncComponent(() => import('./TileBlock/index.vue')),
    title_text_block: defineAsyncComponent(
        () => import('./TitleTextBlock/index.vue'),
    ),
    video_block: defineAsyncComponent(() => import('./VideoBlock/index.vue')),
})
