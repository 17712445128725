export default defineNuxtPlugin((nuxtApp) => {
    return {
        provide: {
            wordBuilder: (sentence, options = { startIndex: 0 }) => {
                return new Promise((resolve, reject) => {
                    const trimmedSentence = sentence.trim()
                    let groupedWords = ''
                    let nextIndex = 0

                    const splitSentence = trimmedSentence.split(' ')

                    splitSentence.forEach((word, index) => {
                        nextIndex = options.startIndex
                            ? options.startIndex + 1 + index
                            : options.startIndex + index
                        groupedWords += ` <span class="word" style="--index:${nextIndex}"><span>${word}</span></span>`
                    })

                    resolve({ words: groupedWords.trim(), endIndex: nextIndex })
                })
            },
        },
    }
})
