import { defineStore } from 'pinia'

export const useCursorStore = defineStore('cursor', {
    state: () => ({
        cursorType: 'default',
        cursorText: null,
        cursorReleaseLocation: { x: 0, y: 0 },
    }),

    actions: {
        setCursorType(newCursorType) {
            if (this.cursorType === newCursorType) return
            this.cursorType = newCursorType
        },
        setCursorText(newCursorText) {
            this.cursorText = newCursorText
        },
        setCursorReleaseLocation(newCursorReleaseLocation) {
            this.cursorReleaseLocation = newCursorReleaseLocation
        },
    },
})
