<template>
    <component
        class="CustomButton"
        :class="['CustomButton--theme-' + theme]"
        :is="componentType"
        :to="to"
        :href="!to && href ? href : null"
        :target="href ? '__blank' : ''"
        @mouseover="toggleCursorOn"
        @mouseleave="toggleCursorOff"
    >
        <span class="CustomButton__content">
            <span class="CustomButton__text">
                <slot />
            </span>
        </span>
    </component>
</template>

<script setup>
import { CustomLink } from '#components'

const props = defineProps({
    to: {
        type: [String, Object],
        default: null,
        required: false,
    },
    href: {
        type: String,
        default: null,
        required: false,
    },
    theme: {
        type: String,
        default: 'default',
        required: false,
        validator(value) {
            return ['default', 'plain'].includes(value)
        },
    },
})

const cursorStore = useCursorStore()

const componentType = computed(() => {
    return props.to || props.href ? CustomLink : 'button'
})

function toggleCursorOn() {
    cursorStore.setCursorType('link-hover')
}

function toggleCursorOff() {
    cursorStore.setCursorType('default')
}
</script>

<style lang="scss" scoped>
.CustomButton--theme-default {
    display: inline-block;
    position: relative;
    background: none;
    border: none;
    padding: 0;
    margin-bottom: 0.8rem;
    text-decoration: none;

    &::before {
        content: '';
        display: block;
        top: 0.8rem;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--dark-green);
        transition: transform 0.2s ease;
    }

    &:hover,
    &:focus {
        &::before {
            transform: scale(0.95);
        }

        .CustomButton__content {
            transform: translateY(0.8rem);

            &::before {
                transform: scale(0.95);
            }
        }
    }

    .CustomButton__content {
        display: block;
        position: relative;
        padding: 1.25rem 1.75rem;
        color: var(--black);
        border: 0;
        font-family: var(--font-family-primary);
        font-weight: var(--font-weight-bold);
        font-style: italic;
        text-transform: uppercase;
        transition: transform 0.2s ease;

        &::before {
            content: '';
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: var(--lime-green);
            transition: transform 0.2s ease;
        }
    }
}

.CustomButton__text {
    position: relative;
}

/**

  THEMES

*/
.CustomButton--theme-plain {
    background: none;
    border: 0;
    color: var(--lime-green);
    font-weight: var(--font-weight-bold);
}
</style>
