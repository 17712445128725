import { default as previewJAXKuV5IzyMeta } from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/preview.vue?macro=true";
import { default as legalqwZTbXfdU3Meta } from "/vercel/path0/pages/legal.vue?macro=true";
import { default as indexD5G5qdvAvhMeta } from "/vercel/path0/pages/[landing]/index.vue?macro=true";
import { default as indexyew3ekxfELMeta } from "/vercel/path0/pages/blog/[post]/index.vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as indexDBbnZWdxZ3Meta } from "/vercel/path0/pages/contact/index.vue?macro=true";
import { default as success9yRvfJeRg8Meta } from "/vercel/path0/pages/contact/success.vue?macro=true";
import { default as cookie_45policywxwm0wEQp5Meta } from "/vercel/path0/pages/cookie-policy.vue?macro=true";
import { default as indexeos9zzU0JnMeta } from "/vercel/path0/pages/forms/[form]/index.vue?macro=true";
import { default as successwqy6S37Z3bMeta } from "/vercel/path0/pages/forms/[form]/success.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as slice_45simulatorXNBTl3WVLzMeta } from "/vercel/path0/pages/slice-simulator.vue?macro=true";
import { default as indexA3HkNMLYW7Meta } from "/vercel/path0/pages/work/[work]/index.vue?macro=true";
import { default as indexkZVtCvZexaMeta } from "/vercel/path0/pages/work/index.vue?macro=true";
import { default as component_45stubUHgG5aOkzGMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubUHgG5aOkzG } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: previewJAXKuV5IzyMeta?.name ?? "prismic-preview",
    path: previewJAXKuV5IzyMeta?.path ?? "/preview",
    meta: previewJAXKuV5IzyMeta || {},
    alias: previewJAXKuV5IzyMeta?.alias || [],
    redirect: previewJAXKuV5IzyMeta?.redirect,
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/preview.vue").then(m => m.default || m)
  },
  {
    name: legalqwZTbXfdU3Meta?.name ?? "cookie-policy",
    path: legalqwZTbXfdU3Meta?.path ?? "/cookie-policy",
    meta: { ...(legalqwZTbXfdU3Meta || {}), ...{"slug":"cookie-policy"} },
    alias: legalqwZTbXfdU3Meta?.alias || [],
    redirect: legalqwZTbXfdU3Meta?.redirect,
    component: () => import("/vercel/path0/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: legalqwZTbXfdU3Meta?.name ?? "data-retention-policy",
    path: legalqwZTbXfdU3Meta?.path ?? "/data-retention-policy",
    meta: { ...(legalqwZTbXfdU3Meta || {}), ...{"slug":"data-retention-policy"} },
    alias: legalqwZTbXfdU3Meta?.alias || [],
    redirect: legalqwZTbXfdU3Meta?.redirect,
    component: () => import("/vercel/path0/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: legalqwZTbXfdU3Meta?.name ?? "data-protection-policy",
    path: legalqwZTbXfdU3Meta?.path ?? "/data-protection-policy",
    meta: { ...(legalqwZTbXfdU3Meta || {}), ...{"slug":"data-protection-policy"} },
    alias: legalqwZTbXfdU3Meta?.alias || [],
    redirect: legalqwZTbXfdU3Meta?.redirect,
    component: () => import("/vercel/path0/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: legalqwZTbXfdU3Meta?.name ?? "privacy-policy",
    path: legalqwZTbXfdU3Meta?.path ?? "/privacy-policy",
    meta: { ...(legalqwZTbXfdU3Meta || {}), ...{"slug":"privacy-policy"} },
    alias: legalqwZTbXfdU3Meta?.alias || [],
    redirect: legalqwZTbXfdU3Meta?.redirect,
    component: () => import("/vercel/path0/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: indexD5G5qdvAvhMeta?.name ?? "landing",
    path: indexD5G5qdvAvhMeta?.path ?? "/:landing()",
    meta: indexD5G5qdvAvhMeta || {},
    alias: indexD5G5qdvAvhMeta?.alias || [],
    redirect: indexD5G5qdvAvhMeta?.redirect,
    component: () => import("/vercel/path0/pages/[landing]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyew3ekxfELMeta?.name ?? "blog-post",
    path: indexyew3ekxfELMeta?.path ?? "/blog/:post()",
    meta: indexyew3ekxfELMeta || {},
    alias: indexyew3ekxfELMeta?.alias || [],
    redirect: indexyew3ekxfELMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmfxAx2eWMBMeta?.name ?? "blog",
    path: indexmfxAx2eWMBMeta?.path ?? "/blog",
    meta: indexmfxAx2eWMBMeta || {},
    alias: indexmfxAx2eWMBMeta?.alias || [],
    redirect: indexmfxAx2eWMBMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexDBbnZWdxZ3Meta?.name ?? "contact",
    path: indexDBbnZWdxZ3Meta?.path ?? "/contact",
    meta: indexDBbnZWdxZ3Meta || {},
    alias: indexDBbnZWdxZ3Meta?.alias || [],
    redirect: indexDBbnZWdxZ3Meta?.redirect,
    component: () => import("/vercel/path0/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: success9yRvfJeRg8Meta?.name ?? "contact-success",
    path: success9yRvfJeRg8Meta?.path ?? "/contact/success",
    meta: success9yRvfJeRg8Meta || {},
    alias: success9yRvfJeRg8Meta?.alias || [],
    redirect: success9yRvfJeRg8Meta?.redirect,
    component: () => import("/vercel/path0/pages/contact/success.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policywxwm0wEQp5Meta?.name ?? "cookie-policy",
    path: cookie_45policywxwm0wEQp5Meta?.path ?? "/cookie-policy",
    meta: cookie_45policywxwm0wEQp5Meta || {},
    alias: cookie_45policywxwm0wEQp5Meta?.alias || [],
    redirect: cookie_45policywxwm0wEQp5Meta?.redirect,
    component: () => import("/vercel/path0/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexeos9zzU0JnMeta?.name ?? "forms-form",
    path: indexeos9zzU0JnMeta?.path ?? "/forms/:form()",
    meta: indexeos9zzU0JnMeta || {},
    alias: indexeos9zzU0JnMeta?.alias || [],
    redirect: indexeos9zzU0JnMeta?.redirect,
    component: () => import("/vercel/path0/pages/forms/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: successwqy6S37Z3bMeta?.name ?? "forms-form-success",
    path: successwqy6S37Z3bMeta?.path ?? "/forms/:form()/success",
    meta: successwqy6S37Z3bMeta || {},
    alias: successwqy6S37Z3bMeta?.alias || [],
    redirect: successwqy6S37Z3bMeta?.redirect,
    component: () => import("/vercel/path0/pages/forms/[form]/success.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalqwZTbXfdU3Meta?.name ?? "legal",
    path: legalqwZTbXfdU3Meta?.path ?? "/legal",
    meta: legalqwZTbXfdU3Meta || {},
    alias: legalqwZTbXfdU3Meta?.alias || [],
    redirect: legalqwZTbXfdU3Meta?.redirect,
    component: () => import("/vercel/path0/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQl0qlywOJTMeta?.name ?? "privacy-policy",
    path: privacy_45policyQl0qlywOJTMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyQl0qlywOJTMeta || {},
    alias: privacy_45policyQl0qlywOJTMeta?.alias || [],
    redirect: privacy_45policyQl0qlywOJTMeta?.redirect,
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: slice_45simulatorXNBTl3WVLzMeta?.name ?? "slice-simulator",
    path: slice_45simulatorXNBTl3WVLzMeta?.path ?? "/slice-simulator",
    meta: slice_45simulatorXNBTl3WVLzMeta || {},
    alias: slice_45simulatorXNBTl3WVLzMeta?.alias || [],
    redirect: slice_45simulatorXNBTl3WVLzMeta?.redirect,
    component: () => import("/vercel/path0/pages/slice-simulator.vue").then(m => m.default || m)
  },
  {
    name: indexA3HkNMLYW7Meta?.name ?? "work-work",
    path: indexA3HkNMLYW7Meta?.path ?? "/work/:work()",
    meta: indexA3HkNMLYW7Meta || {},
    alias: indexA3HkNMLYW7Meta?.alias || [],
    redirect: indexA3HkNMLYW7Meta?.redirect,
    component: () => import("/vercel/path0/pages/work/[work]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkZVtCvZexaMeta?.name ?? "work",
    path: indexkZVtCvZexaMeta?.path ?? "/work",
    meta: indexkZVtCvZexaMeta || {},
    alias: indexkZVtCvZexaMeta?.alias || [],
    redirect: indexkZVtCvZexaMeta?.redirect,
    component: () => import("/vercel/path0/pages/work/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/people",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/blog/insight/",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/marketing-ai",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/ai",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/eightandfour-secures-a-double-nomination-at-the-global-social-media-awards",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/an-update-from-eightampfour-from-setting-this-years-agenda-to-welcoming-exciting-new-clients",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/blog/eightampfour-gets-shortlisted-for-best-agency-culture-award-at-the-oystercatchers-2023",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/what-weve-been-up-to-in-november-canon-hero-video-marie-curie-brand-campaign-and-more",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/yg8i3kycqwvw9k23b20qz0mvj2rx64",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/blog/updates-from-eightampfourians-new-wins-live-content-development-and-creative-success",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/blog/the-drum-awards-festival-weve-been-nominated-twice-for-genesis-and-mind",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/unwrapping-success-standing-out-in-the-christmas-crowd",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/blog/eightampfour-takes-home-double-win-at-ipm-awards",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/influencer-marketing",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/eightandfour-scores-a-prestigious-double-shortlisting-in-two-categories-at-the-european-agency-awards-2023",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/double-shortlist-nomination-for-eightampfour-at-the-ipm-awards-with-sandals-resorts-and-genesis-europenbsp",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/uk-social-media-awards-eightampfour-gets-shortlisted-in-three-categories",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/ai-x-creative-how-generative-artificial-intelligence-can-support-creativity",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/why-social-commerce-in-2023",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/blog/ai-roadmap",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/insight/blog/eightampfour-wins-best-use-of-social-media-at-automotive-marketing-amp-communications-awards-for-work-with-genesis",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/blog/insight/30-secondsgoogle-hummingbird-algorithm",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  }
]