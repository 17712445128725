<template>
    <div
        class="SideNav__overlay"
        :class="{
            'SideNav__overlay--open': open,
        }"
        @mouseenter="cursorStore.setCursorType('close')"
        @click="handleOverlayClick"
        @mouseleave="cursorStore.setCursorType('default')"
    ></div>
    <div
        class="SideNav"
        :class="{
            'SideNav--open': open,
            'SideNav__has-promo-bar': showingPromoBar,
        }"
    >
        <ul class="SideNav__list">
            <li class="SideNav__item" v-for="link in links">
                <CustomLink
                    class="SideNav__item-link"
                    :to="link.internal_link.url"
                    :href="link.external_link.url"
                    @click="emits('close')"
                >
                    <span class="SideNav__link-text">
                        {{ link.link_text }}
                    </span>
                    <div class="SideNav__item-arrow">
                        <FontIcon icon="arrow" color="black" size="stretch" />
                    </div>
                </CustomLink>
            </li>
        </ul>
    </div>
</template>

<script setup>
const cursorStore = useCursorStore()

const emits = defineEmits(['close'])

const props = defineProps({
    open: {
        type: Boolean,
        default: false,
    },
    links: {
        type: Array,
        default: () => [],
    },
    showingPromoBar: {
        type: Boolean,
        default: false,
    },
})

function handleOverlayClick() {
    emits('close')
}
</script>

<style lang="scss">
.SideNav {
    position: fixed;
    height: 100dvh;
    overflow: auto;
    top: 0;
    transform: translateX(100%);
    min-width: min-content;
    width: 90%;
    background-color: var(--lime-green);
    right: 0;
    padding-top: 4.625rem; // height of quicklinks plus offset margin top
    color: var(--black);
    transition: transform 0.5s ease;
    padding-left: clamp(1rem, 1vw, 6rem);
    padding-right: clamp(1rem, 1vw, 6rem);

    @include medium-up {
        width: clamp(20rem, 60%, 25rem);
    }

    @include large-up {
        width: clamp(25rem, 40vw, 30rem);
        padding-left: clamp(2rem, 1vw, 6rem);
        padding-right: clamp(2rem, 1vw, 6rem);
    }
}

.SideNav--open {
    transform: translateX(0);
}

.SideNav__has-promo-bar {
    padding-top: calc(4.625rem + var(--promo-bar-height));
}

.SideNav__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(var(--black-rgb), 0.5);
    opacity: 0;
    transform: translateX(100%);
    transition:
        opacity 0.3s ease,
        transform 0.1s 0.3s linear;
}

.SideNav__overlay--open {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.3s ease;
}

.SideNav__list {
    margin-top: 4rem;
}

.SideNav__item {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3.25rem;
}

.SideNav__item-link {
    @include heading-3;

    display: flex;
    align-items: flex-start;
    text-align: right;
    line-height: 0.7;
    color: var(--black);
    text-decoration: none;

    &:hover {
        .SideNav__item-arrow {
            transform: translate(5px, -5px);
        }

        .SideNav__link-text::after {
            width: 100%;
        }
    }

    @include large-up {
        @include heading-2;

        line-height: 0.7;
    }
}

.SideNav__link-text {
    &::after {
        position: relative;
        top: 0.5rem;
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background-color: var(--black);
        transition: width 0.2s ease;
    }
}

.SideNav__item-arrow {
    width: 1rem;
    margin-left: 1rem;
    transform: translate(0);
    transition: transform 0.2s ease;

    @include large-up {
        margin-left: 2rem;
    }
}
</style>
