<template>
    <div
        class="smart-image"
        :class="[
            'smart-image--' + positioning,
            asBg ? 'smart-image--as-background' : '',
            'smart-image--background-' + backgroundColor,
            hasLoaded ? 'smart-image--loaded' : '',
            withPlaceholder ? 'smart-image--with-placeholder' : '',
            { 'smart-image--offset-frame': offsetFrame },
        ]"
        :style="{
            ...((positioning === 'absolute' || !url) && padding
                ? { aspectRatio: `auto ${width} / ${height}` }
                : {}),
        }"
    >
        <img
            v-if="url"
            :srcset="srcset"
            :sizes="sizes"
            :src="url"
            :width="width"
            :height="height"
            :loading="lazyLoad ? 'lazy' : 'eager'"
            :title="title"
            :alt="alt"
            @load="hasLoaded = true"
            :style="{
                ...(objectPosition ? { objectPosition } : {}),
            }"
        />
        <div v-else-if="withPlaceholder" class="smart-image__placeholder">
            <img
                v-if="placeholderImage.url"
                :src="placeholderImage.url"
                class="smart-image__placeholder-image"
            />
            <svg
                v-else
                width="132"
                height="28"
                viewBox="0 0 132 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="smart-image__placeholder-image"
            >
                <path
                    d="M15.3533 14.3933H4.57969C4.66784 15.4305 5.0396 16.2401 5.69882 16.8295C6.3542 17.4153 7.1629 17.7101 8.12106 17.7101C9.50465 17.7101 10.4896 17.1317 11.076 15.9751H15.0927C14.6672 17.3556 13.8509 18.4935 12.6436 19.3815C11.4364 20.2694 9.95688 20.7134 8.20155 20.7134C6.78347 20.7134 5.51102 20.4074 4.38422 19.7956C3.25742 19.1837 2.37974 18.3144 1.75119 17.1914C1.12263 16.0685 0.80835 14.7738 0.80835 13.3076C0.80835 11.8414 1.1188 10.5206 1.73968 9.39767C2.36057 8.27468 3.23059 7.41285 4.34589 6.80846C5.46503 6.20405 6.74897 5.90186 8.20539 5.90186C9.66179 5.90186 10.8614 6.19659 11.9691 6.78234C13.0767 7.36808 13.939 8.20379 14.5484 9.28202C15.1616 10.3603 15.4683 11.5989 15.4683 12.998C15.4683 13.5165 15.4338 13.9829 15.361 14.397L15.3533 14.3933ZM11.6012 11.957C11.582 11.0243 11.237 10.2782 10.5625 9.71848C9.88792 9.15889 9.0639 8.87535 8.08657 8.87535C7.1629 8.87535 6.3887 9.1477 5.76015 9.69241C5.13159 10.2372 4.74449 10.9907 4.60268 11.957H11.6012Z"
                    fill="white"
                />
                <path
                    d="M17.0971 3.81239C16.664 3.40573 16.4456 2.90206 16.4456 2.29766C16.4456 1.69326 16.664 1.1896 17.0971 0.782935C17.5302 0.376271 18.0783 0.174805 18.7337 0.174805C19.3891 0.174805 19.9333 0.376271 20.3702 0.782935C20.8033 1.1896 21.0218 1.69326 21.0218 2.29766C21.0218 2.90206 20.8033 3.40573 20.3702 3.81239C19.9372 4.21905 19.3891 4.42052 18.7337 4.42052C18.0783 4.42052 17.5302 4.21905 17.0971 3.81239ZM20.5657 6.13299V20.4819H16.8404V6.13299H20.5657Z"
                    fill="white"
                />
                <path
                    d="M31.5806 6.53234C32.4122 6.95392 33.0715 7.50236 33.5506 8.17765V6.13313H37.3027V20.5827C37.3027 21.9109 37.0268 23.0973 36.4787 24.142C35.9306 25.1866 35.1027 26.0148 34.0028 26.6268C32.9029 27.2386 31.5729 27.5445 30.0131 27.5445C27.9204 27.5445 26.2034 27.0707 24.8657 26.1193C23.5281 25.168 22.7693 23.8733 22.593 22.2355H26.2915C26.487 22.8922 26.9086 23.4145 27.5563 23.8024C28.204 24.1905 28.9897 24.3845 29.9095 24.3845C30.9903 24.3845 31.8681 24.0711 32.5425 23.4406C33.2171 22.8101 33.5544 21.855 33.5544 20.579V18.3517C33.0753 19.027 32.416 19.5866 31.5729 20.0343C30.7297 20.482 29.7677 20.7058 28.6869 20.7058C27.4452 20.7058 26.3107 20.3962 25.2797 19.7732C24.2525 19.1501 23.4399 18.2771 22.8459 17.1429C22.2519 16.0125 21.9529 14.7141 21.9529 13.2441C21.9529 11.7742 22.248 10.5094 22.8459 9.38644C23.4399 8.26345 24.2487 7.40163 25.2681 6.79723C26.2877 6.19283 27.426 5.89062 28.6869 5.89062C29.7869 5.89062 30.7527 6.10329 31.5882 6.52487L31.5806 6.53234ZM33.0178 11.0392C32.6613 10.4087 32.1823 9.92739 31.5806 9.58791C30.9788 9.25213 30.3311 9.08425 29.6374 9.08425C28.9437 9.08425 28.3075 9.2484 27.7211 9.57672C27.1347 9.90498 26.6595 10.3826 26.2991 11.0131C25.935 11.6436 25.755 12.3897 25.755 13.2516C25.755 14.1135 25.935 14.8708 26.2991 15.5163C26.6633 16.1654 27.1424 16.6616 27.7364 17.0048C28.3304 17.3518 28.9629 17.5234 29.6374 17.5234C30.3119 17.5234 30.975 17.3556 31.5806 17.0198C32.1823 16.684 32.6613 16.199 33.0178 15.5685C33.3704 14.9379 33.5506 14.1844 33.5506 13.3038C33.5506 12.4233 33.3742 11.6698 33.0178 11.0392Z"
                    fill="white"
                />
                <path
                    d="M50.3645 6.63644C51.2345 7.11026 51.9128 7.81166 52.3996 8.73318C52.8864 9.6584 53.1317 10.7665 53.1317 12.0611V20.4779H49.4063V12.5536C49.4063 11.4156 49.115 10.539 48.5286 9.92334C47.9422 9.31146 47.145 9.00553 46.1332 9.00553C45.1213 9.00553 44.2935 9.31146 43.6995 9.92334C43.1054 10.5352 42.8064 11.412 42.8064 12.5536V20.4779H39.0811V1.3125H42.8064V7.91612C43.2855 7.29307 43.9256 6.80806 44.7228 6.45363C45.52 6.0992 46.4091 5.92384 47.3827 5.92384C48.5018 5.92384 49.4945 6.16262 50.3606 6.63644H50.3645Z"
                    fill="white"
                />
                <path
                    d="M59.678 9.11023V16.0496C59.678 16.5346 59.7968 16.8816 60.0382 17.098C60.2758 17.3144 60.6821 17.4226 61.2494 17.4226H62.9779V20.4782H60.6361C57.4972 20.4782 55.9258 18.9933 55.9258 16.0236V9.11023H54.1704V6.133H55.9258V2.58496H59.678V6.133H62.9779V9.11023H59.678Z"
                    fill="white"
                />
                <path
                    d="M90.4236 9.11044H87.8442V20.4783H84.0652V9.11044H82.3904V6.13322H84.0652V5.40943C84.0652 3.64847 84.5788 2.35385 85.6098 1.52561C86.6369 0.697358 88.1891 0.309349 90.2664 0.361581V3.41715C89.362 3.3985 88.7334 3.54774 88.377 3.8574C88.0206 4.16706 87.8442 4.73042 87.8442 5.54001V6.13695H90.4236V9.11417V9.11044Z"
                    fill="white"
                />
                <path
                    d="M94.2296 19.7917C93.0951 19.1798 92.2021 18.3105 91.5543 17.1875C90.9066 16.0645 90.5847 14.7699 90.5847 13.3037C90.5847 11.8375 90.9182 10.5429 91.5812 9.41988C92.2481 8.29688 93.1565 7.43133 94.3101 6.81574C95.4637 6.20387 96.7476 5.89795 98.1695 5.89795C99.591 5.89795 100.875 6.20387 102.029 6.81574C103.183 7.4276 104.091 8.29688 104.758 9.41988C105.425 10.5429 105.755 11.8375 105.755 13.3037C105.755 14.7699 105.414 16.0645 104.731 17.1875C104.048 18.3105 103.125 19.1761 101.964 19.7917C100.803 20.4035 99.5033 20.7094 98.0661 20.7094C96.6288 20.7094 95.3678 20.4035 94.2334 19.7917H94.2296ZM99.9633 17.0718C100.557 16.751 101.032 16.2734 101.385 15.6355C101.737 14.9975 101.918 14.2215 101.918 13.3037C101.918 11.9382 101.55 10.8898 100.814 10.1586C100.079 9.4236 99.1772 9.05798 98.1121 9.05798C97.0465 9.05798 96.1574 9.4236 95.4368 10.1586C94.7202 10.8936 94.3598 11.9419 94.3598 13.3037C94.3598 14.6655 94.7086 15.7176 95.41 16.4488C96.1114 17.1838 96.9929 17.5494 98.0584 17.5494C98.733 17.5494 99.3654 17.389 99.9594 17.0718H99.9633Z"
                    fill="white"
                />
                <path
                    d="M121.074 6.13281V20.4817H117.321V18.6685C116.843 19.2916 116.217 19.7766 115.447 20.131C114.676 20.4855 113.837 20.6608 112.933 20.6608C111.779 20.6608 110.76 20.422 109.874 19.9481C108.989 19.4744 108.291 18.7729 107.786 17.8514C107.28 16.9299 107.026 15.8256 107.026 14.5497V6.13281H110.752V14.0311C110.752 15.1689 111.043 16.0457 111.63 16.6613C112.216 17.2732 113.014 17.5791 114.025 17.5791C115.037 17.5791 115.861 17.2732 116.448 16.6613C117.034 16.0494 117.325 15.1727 117.325 14.0311V6.13281H121.077H121.074Z"
                    fill="white"
                />
                <path
                    d="M128.481 6.57349C129.253 6.14071 130.133 5.92432 131.13 5.92432V9.72982H130.145C128.976 9.72982 128.091 9.99842 127.497 10.532C126.903 11.0654 126.604 11.9982 126.604 13.33V20.4784H122.878V6.13324H126.604V8.36057C127.082 7.59948 127.708 7.00627 128.478 6.57349H128.481Z"
                    fill="white"
                />
                <path
                    d="M79.167 17.0049C79.6691 16.3557 80.0984 15.6469 80.4318 14.8932C80.8495 13.9531 81.206 12.9271 81.4935 11.8302H78.0479C77.8524 12.4607 77.6301 13.0577 77.3848 13.6135C77.2123 14.0052 77.0016 14.3747 76.7639 14.7253L76.4726 14.4493L76.0089 14.0128L71.0264 9.27457C70.4783 8.7224 70.0721 8.22993 69.8191 7.80833C69.5471 7.3569 69.409 6.91666 69.409 6.49134C69.409 6.01379 69.5892 5.61832 69.9495 5.31612C70.2944 5.02139 70.7313 4.87215 71.2373 4.87215C71.7432 4.87215 72.1762 5.02885 72.4981 5.34224C72.7626 5.59966 72.9121 5.93918 72.9466 6.35703H76.7448C76.7294 5.63697 76.5417 4.96542 76.1813 4.35356C75.7789 3.66335 75.158 3.10372 74.3379 2.68959C73.5139 2.27174 72.4867 2.05908 71.2871 2.05908C70.0875 2.05908 69.0679 2.26055 68.2133 2.65602C67.37 3.04403 66.7185 3.585 66.2816 4.26029C65.8408 4.93557 65.6186 5.69294 65.6186 6.52119C65.6186 7.10321 65.7144 7.64418 65.8983 8.12919C66.0708 8.58436 66.9715 9.94988 67.1478 10.2073L67.3203 10.4573L67.0558 10.6176C66.251 11.1101 65.6147 11.6922 65.1702 12.3488C64.5607 13.2442 64.2542 14.2552 64.2542 15.3596C64.2542 16.4639 64.5339 17.4041 65.0897 18.21C65.6454 19.0196 66.4503 19.6538 67.4851 20.0978C68.5352 20.5492 69.7885 20.7768 71.2105 20.7768C73.2609 20.7768 75.0968 20.2881 76.6643 19.3218L76.8636 19.1987L78.228 20.4858H82.8004L79.167 17.0123V17.0049ZM74.0159 17.0982C73.211 17.4937 72.341 17.6914 71.4288 17.6914C70.4477 17.6914 69.6543 17.4414 69.0642 16.9452C68.4624 16.4378 68.1558 15.7961 68.1558 15.0387C68.1558 14.0724 68.6118 13.2517 69.5049 12.5988L69.7119 12.4495L69.8958 12.6248C70.739 13.4344 72.5634 15.1805 74.0887 16.6281L74.3916 16.9153L74.0159 17.0982Z"
                    fill="#58F97B"
                />
            </svg>
        </div>
    </div>
</template>
<script>
export default defineNuxtComponent({
    props: {
        url: {
            type: String,
        },
        srcs: {
            type: Array,
            required: true,
            default: () => [
                {
                    slotWidth: 400,
                    width: 400,
                },
            ],
        },
        width: {
            type: Number,
            required: true,
        },
        height: {
            type: Number,
            required: true,
        },
        lazyLoad: {
            type: Boolean,
            default: true,
        },
        positioning: {
            type: String,
            default: 'absolute',
            validator(value) {
                return ['relative', 'absolute'].includes(value)
            },
        },
        asBg: {
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            type: String,
            default: 'transparent',
            validator(value) {
                return ['transparent', 'white'].includes(value)
            },
        },
        padding: {
            type: Boolean,
            default: true,
        },
        withPlaceholder: {
            type: Boolean,
            default: true,
        },
        placeholderImage: {
            type: Object,
            default: () => ({}),
        },
        offsetFrame: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        alt: {
            type: String,
            default: '',
        },
        objectPosition: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            hasLoaded: false,
        }
    },

    computed: {
        srcset() {
            if (!this.url) return

            let srcset = ''
            const baseUrl = this.url.split('?')[0]
            const originalUrlParams = new URL(this.url).searchParams

            this.srcs.forEach((src, index) => {
                const dedupedUrlParams = new URLSearchParams({
                    ...Object.fromEntries(originalUrlParams),
                    ...{ w: src.width },
                    ...{ auto: 'format' },
                })

                srcset +=
                    baseUrl +
                    '?' +
                    dedupedUrlParams.toString() +
                    ` ${src.width}w`

                if (index + 1 < this.srcs.length) {
                    srcset += ', '
                }
            })

            return srcset
        },

        sizes() {
            let sizes = ''
            const breakpoints = [650, 1071, 1439]

            this.srcs.forEach((src, index) => {
                if (src.width <= 650 && breakpoints[0] === 650) {
                    sizes += `(max-width: 650px) ${src.width}px`
                    breakpoints.shift()
                } else if (
                    src.width <= 1071 &&
                    breakpoints[0] === 1071 &&
                    index + 1 < this.srcs.length
                ) {
                    sizes += `(max-width: 1071px) ${src.width}px`
                    breakpoints.shift()
                } else if (
                    src.width <= 1439 &&
                    breakpoints[0] === 1439 &&
                    index + 1 < this.srcs.length
                ) {
                    sizes += `(max-width: 1439px) ${src.width}px`
                    breakpoints.shift()
                } else {
                    sizes += src.width + 'px'
                }

                if (index + 1 < this.srcs.length) {
                    sizes += ', '
                }
            })

            return sizes
        },
    },

    watch: {
        hasLoaded(newValue) {
            if (newValue) {
                this.$emit('loaded', true)
            }
        },
    },
})
</script>
<style lang="scss" scoped>
.smart-image {
    position: relative;

    img {
        position: absolute;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.smart-image--with-placeholder {
    background-color: var(--black-30);

    &.smart-image--loaded {
        background-color: transparent;

        &:after {
            content: none;
        }
    }
}

.smart-image--relative {
    img {
        position: relative;
    }
}

.smart-image--as-background img {
    height: 100%;
    object-fit: cover;
}

.smart-image--background-white {
    background-color: var(--white);
}

.smart-image--offset-frame {
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: var(--black-30);
        top: -2%;
        left: -1%;
        position: absolute;
    }
}

.smart-image__placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--black-30);
}

.smart-image__placeholder-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    max-width: 130px;
}
</style>
